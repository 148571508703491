.nft_marketplace_links_modal {

    .modal_close_btn {
        position: absolute;
        right: 20px;
        top: 12px;
        cursor: pointer;
        color: #2330D0;
        z-index: 1;
    }

    .nft_marketplace_links_modal_content {
        width: 1000px;

        @media (max-width: 768px) {
            width: 100%;
        }

        .nft_marketplace_links_modal_body {
            padding: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .horizontal_separator {
                border: 1px solid #E9E9E9;
                width: 100%;
                margin: 16px 0;
            }

            .nft_marketplace_links_modal_title {
                font: normal normal normal 18px/22px Curse Casual;
                text-align: center;
            }

            .tower_cards_marketplace_links_container {
                display: flex;
                margin-top: 16px;

                .tower_cards_img {
                    width: 130px;
                    height: 98px;
                    margin-right: 16px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .marketplace {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .marketplace_links {
                        display: flex;

                        .marketplace_link {
                            width: 32px;
                            height: 32px;
                            margin-right: 12px;

                            &:last-child {
                                margin-right: 0;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    .chain_text {
                        font: normal normal normal 12px/15px Roboto;
                        color: #6F6F6F;
                        opacity: 0.7;
                        margin-top: 6px;
                    }
                }

                .vertical_separator {
                    border: 1px solid #2448A8;
                    margin: 0 16px;
                }
            }

            .tickets_marketplace_links_modal_title {
                font: normal normal normal 14px/16px Roboto;
                text-align: center;
            }
        }
    }
}