.container_header {
    width: 100%;
    top: 0;
    padding: 25px;
    z-index: 2;
    background-color: transparent;

    @media (min-width: 992px) {
        height: 135px;
    }

    @media (max-width: 992px) {
        background-color: #000;
    }

    .tower_logo {
        width: 44px;
    }

    .toggle_btn {
        position: absolute;
        left: 16px;
        top: 20px;
        color: #C7C7C7 !important;
        border: 1px solid #C7C7C7 !important;
    }

    // .nav_brand {
    //     margin: 0;
        
    //     img {
    //         content: url('../../assets/icons/logo-tower.svg');
    //     }
    // }

    .mobile_only {
        display: flex;
        justify-content: center;
        flex: 1;
        margin-right: 0;

        @media (min-width: 992px) {
            display: none !important;
        }

        img {
            width: 44px;
            height: 44px;
            cursor: pointer;
        }
    }
    .divider_gold {
        height: 38px;
        border: 1px solid #e5ccaf;
    }

    .divider {
        height: 46px;
        border: 1px solid #C7C7C7;
    }

    .tower_container {
        background-color: #c3a689;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-align: left;
        display: flex;
        margin-bottom: 12px;
        img {
            margin: 6px 10px;

            width: 30px;
            height: 30px;
            margin-right: 6px;
        }

        .tower_text {
            margin: 6px 10px;
            width: 63%;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-top: 9px;
            font: normal normal bold 20px/12px Arial;
            color: #555555;
        }

        @media (min-width: 992px) {
            width: 180px;
        }
    }

    .navbar_collapse {
        text-align: center;
        justify-content: flex-end;

    }

    .navbar_collapse_child {
        text-align: center;
        justify-content: flex-end;
        padding: 0;
        // background-color: #f2f2f2;
        background-color: #000;
    }

    .wallet_related {
        display: flex;
        padding: 16px 0 !important;

        .token_details {
            display: flex;
            width: 100%;

            .tower_rewards_container {
                display: flex;
                align-items: center;

                .content {
                    height: 32px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font: normal normal normal 10px/14px Roboto;

                    @media (min-width: 992px) {
                        display: none;
                    }
                }
            }

            @media (max-width: 992px) {
                display: flex;
                flex-direction: column;

                .tower_rewards_container {
                    flex-direction: column;

                    .arrow {
                        display: none;
                    }
                }
            }

            .towerGoldContainer {
                position: relative;
                display: flex;
                align-items: center;
                background-color: #c3a689;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                text-align: left;
                display: flex;
                width: 141px;
                height: 38px;
                margin: 0 10px;
                
                &:hover{
                    .popover{
                        display: block;
                    }
                }

                @media (max-width: 992px) {
                    margin: 10px 0;
                    width: 100%;

                    &:hover{
                        .popover{
                            display: none;
                        }
                    }
                }

                img {
                    margin: 4px;
                    width: 30px;
                    height: 30px;
                }

                .popover {
                    display: none;
                    position: absolute;
                    width: 190px;
                    height: auto;
                    left: -25px;
                    top: 45px;
                    padding: 10px;
                    background: #ffffff;
                    border-radius: 4px;
                   img{
                       height: 16px;
                       width: 16px;
                   }
                    .pop_over_content  {                        
                        height: 32px;
                        align-items: center;
                        font: normal normal normal 10px/14px Roboto;
                        
                        span {
                            font-family: Roboto;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.20000000298023224px;
                            text-align: left;
                            color: black;
                        }
                        .link {
                            font-family: Roboto;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.20000000298023224px;
                            float: right;
                            color: #2330D0;
                            padding-top: 10px;

                        }
                    }
                }
               
                  .popover.arrowTop:after {
                    content: " ";
                    position: absolute;
                    right: 70px;
                    top: -8px;
                    border-top: none;
                    border-right: 8px solid transparent;
                    border-left: 8px solid transparent;
                    border-bottom: 8px solid #fff;
                  }

                .tower_text {
                    display: flex;
                    align-items: center;
                    padding: 10px !important;
                    font-family: Curse Casual;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.20000000298023224px;
                    text-align: left;
                }
            }
            .network_and_towers {
                display: flex;
                align-items: center;
                margin: 0 10px;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                border-radius: 4px;
                height: 38px;
                padding: 8px;
                text-align: left;
                display: flex;

                @media (max-width: 992px) {
                    margin: 16px 0;
                }

                .network_logo_name_plus_unseen_span {
                    position: relative;

                    .network_logo_name {
                        position: relative;
                        width: 111px;
                        display: flex;
    
                        .network_logo {
                            margin-right: 4px;
                            width: 18px !important;
                            height: 18px !important;
                        }
    
                        .network_name {
                            margin-left: 4px;
                            font-family: Curse Casual;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 18px;
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.2px;
                            color: #0D1C2B;
                        }
    
                        .network_list {
                            z-index: 23;
                            position: absolute;
                            width: 108px;
                            height: 131px;
                            border-radius: 4px;
                            background: #ffffff;
                            display: none;
                            flex-direction: column;
                            margin-top: 49px;
                            
                            .select_network_text {
                                position: absolute;
                                white-space: nowrap;
                                margin-top: 5px;
                                margin-left: 19px;
                                margin-right: 19px;
                                width: 70px;
                                height: 14px;
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: normal;
                                text-overflow: clip;
                                font-size: 10px;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                letter-spacing: 0.2px;
                                color: #D2D2D2;
                            }
    
                            .horizontal_ruler {
                                position: absolute;
                                height: 1px;
                                width: 100%;
                                background: #f2f2f2;
                                margin-top: 21px;
                            }
    
                            .network_dropdown_list {
                                width: 100%;
                                margin-top: 31px;
    
                                .cursor_pointer {
                                    cursor: pointer;
                                }
    
                                .network_dropdown_item {
                                    border-radius: 4px;
                                    margin-left: 5px;
                                    margin-right: 5px;
                                    height: 20px;
                                    display: flex;
                                    margin-bottom: 14px;
                                    flex-direction: row;
    
                                    .network_dropdown_item_img {
                                        width: 18px;
                                        height: 18px;
                                        margin-top: 0px !important;
                                    }
    
                                    .network_dropdown_item_text {
                                        position: relative;
                                        font-family: Curse Casual;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 18px;
                                        margin-top:inherit;
                                        align-items: center;
                                        letter-spacing: 0.2px;
                                        color: #0D1C2B;
                                    }
    
                                    .network_dropdown_item_tick {
                                        width: 10px;
                                        margin-left: auto;
                                        justify-content: center;
                                        color: #1DD72F;
                                    }
    
                                    &:hover {
                                        background: #f2f2f2;
                                    }
                                }
                            }
    
                        }
    
                        .network_options {
                            display: none;
                            border: 0;
                            background-color: #fff;
                            border-right: 1px solid #F2F2F2;
                            height: 100%;
                            width: 120px;
                            border-radius: 4px 0 0 4px;
                        }
    
                        .network_chevron_arrow {
                            margin-right: 5px;
                            margin-left: auto;
                            position: relative;
                            width: 0.5em;
                            height: auto;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
                    }

                    .unseen_span {
                        position: absolute;
                        margin-top: -36px;
                        width: 111px;
                        height: 80px;
                    }
                }

                .network_logo_name_plus_unseen_span:hover .network_list {
                    display: flex;
                }
                

                img {
                    margin: 6px;
                    width: 18px;
                    height: 18px;
                }

                .vertical_line {
                    height: 100%;
                    width: 1px;
                    background: #f2f2f2;
                }

                .towers {
                    // border: 1px solid red;
                    width: 144px;
                    display: flex;

                    @media (max-width: 992px) {
                        width: 100%;
                    }

                    .tower_logo {
                        width: 30px;
                        height: 30px;
                    }

                    .tower_text {
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        font-family: Curse Casual;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.20000000298023224px;
                        text-align: left;
                        min-width: 79px;
                    }
    
                    .add_tower_icon {
                        width: 20px;
                        height: 20px;
                        margin-left: auto;
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-right: 9px;
                        color: #2330D0;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
    
                        &:active {
                            color: #676FD7;
                        }
                    }
                }

                .otherTokens {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin: 6px 10px;
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                    }
                }

                
            }
        }
        > * {
            flex: 1;
        }
    }

    .description {
        //styleName: Button;
        font-family: Curse Casual;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
    }

    .description_parent {
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;

        svg {
            margin-left: 10px;
            cursor: pointer;
        }
        img {
            padding-right: 10px;
            height: 20px;
        }
    }

    .logout_section {
        color: #2330d0;
        text-transform: uppercase;
        font: normal normal normal 14px/12px Curse Casual;
        padding: 0.75rem 1.5rem;
        cursor: pointer;

        @media (max-width: 992px) {
            font: normal normal normal 20px/12px Curse Casual;
        }
    }

    .navbar_collapse_nav {
        display: flex;
        font: normal normal normal 20px/16px Curse Casual;

        a {
            color: #2330d0;
        }

        @media (min-width: 992px) {
            justify-content: flex-end;
        }

        @media (max-width: 992px) {
            > * {
                padding: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .divider {
                padding: 0px;
            }
        }

        .nav_div {
            cursor: pointer;
            display: block;
            // color: #2330d0;
            color: #FFFFFF;
            // border-bottom: 1px solid #f2f2f2;
            border-bottom: 1px solid #222222;

            svg {
                margin-left: 7px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .navbar_collapse_nav_child {

        a {
            border-bottom: 1px solid #222222;
        }

        @media (max-width: 992px) {
            > * {
                padding: 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .divider {
                padding: 0px;
            }
        }
    }

    .connected_mobile_content {
        display: flex;
        justify-content: space-between;
    }

    .wallet_mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #0d1c2b;

        .title {
            font-size: bold;
            text-transform: capitalize;
            font: normal normal bold 14px/19px Roboto;
        }

        .description {
            font: normal normal normal 16px/20px Roboto;
        }
    }

    .btn_connect {
        margin-left: 12px;
        height: 38px;
        width: 80% !important;

        @media (max-width: 992px) {
            width: 100%;
            max-width: 220px;
            height: 52px;
            background: radial-gradient(31.66% 100% at 50% 0%, #FFF8BB 0%, rgba(255, 230, 0, 0) 100%), #F9CB64;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font: normal normal normal 18px/26px Curse Casual;
            color: #392720;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            margin-left: 0;
            border: none;

            &:hover {
                color: #392720;
            }

            .btn_ornament {
                width: 20px;
                height: 48px;
                background-image: url('../../assets/imgs/CoinbaseHome/btn_ornmant_yellow.png');

                &.left_ornament {
                    position: absolute;
                    left: 2px;
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }

                &.right_ornament {
                    position: absolute;
                    right: 2px;
                }
            }
        }
    }

    .btn_connected_mobile {
        cursor: inherit !important;
        padding: 0 10px !important;
    }

    .dropdown_button_connected {
        width: 180px;
        margin-left: 12px;
        display: none;

        button {
            width: 100%;
        }

        @media (min-width: 992px) {
            display: block;
        }
    }

    .translation {
        @media (min-width: 992px) {
            width: 180px;
            text-align: center;
            display: flex;
            padding: 8px;
        }
    }

    .lang_select {
        align-items: center;
        justify-content: center;
        text-shadow: 2px 2px #000;
    }
}