.discord_container {
  position: fixed;
  right: 40px;
  bottom: -6px;
  z-index: 102;
  transition: bottom 0.5s ease-in-out;

  &.collapsed {
    bottom: -148px;
  }

  @media (max-width: 640px) {
    width: 100%;
    right: unset;
    bottom: 0;

    &.collapsed {
      bottom: -585px;
    }
  }

  a, a:hover {
    text-decoration: none;
  }

  .wooden_board {
    height: 190px;
    width: 300px;
    background-image: url('../../assets/imgs/home/wooden-board.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;

    @media (max-width: 640px) {
      display: none;
    }

    .title {
      font: normal normal normal 16px/26px Curse Casual;
      color: #FFFFFF;
      margin-bottom: 4px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
    }

    .store_container {
      display: flex;

      .app_qr {
        width: 98px;
        height: 98px;
      }

      .store_btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          width: 119px;
          height: 35px;
          margin: 0 8px 4px 8px;

          img {
            width: 100%;
          }
        }
      }
    }

    .nfts_marketplace_btn {
      margin-top: 12px;
      color: #FFFFFF;
      font: normal normal normal 14px/24px Roboto;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .wooden_board_mobile {
    background: #915D40;
    border-radius: 14px 14px 0px 0px;
    display: none;

    @media (max-width: 640px) {
      display: block;  
    }

    .title {
      font: normal normal normal 16px/26px Curse Casual;
      background-color: #734425;
      color: #FFFFFF;
      border-radius: 14px 14px 0px 0px;
      margin-bottom: 4px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 16px;
    }

    .contents {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 22px;
      max-height: 394px;
      overflow: auto;

      .section_title {
        font: normal normal normal 20px/26px Curse Casual;
        color: #E1C8AF;
        margin-bottom: 10px;
      }

      .app_store_links {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .app_img {
          width: 102px;
          height: 102px;
          object-fit: contain;
          border-radius: 24px;
        }
  
        .store_btns {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
          a {
            width: 166px;
            height: 48px;
            margin: 0 8px 4px 8px;
  
            img {
              width: 100%;
            }
          }
        }
      }

      .marketplace_container {
        display: flex;
        flex-direction: column;
        background: #845236;
        border-radius: 6px;
        padding: 8px;
        margin-bottom: 10px;

        .marketplace_container_title {
          font: normal normal normal 14px/18px Roboto;
          color: #D6D6D6;
          margin-bottom: 4px;
        }
        
        .marketplace_links_container {
          display: flex;
          justify-content: center;
          align-items: center;
          
          .marketplace_type_icon {
            width: 90px;
            height: 90px;
            object-fit: contain;
            margin-right: 18px;
          }

          .marketplace {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
  
            .marketplace_links {
              display: flex;
  
              .marketplace_link {
                width: 32px;
                height: 32px;
                margin-right: 12px;
  
                &:last-child {
                  margin-right: 0;
                }
  
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
  
            .chain_text {
              font: normal normal normal 12px/15px Roboto;
              color: #D6D6D6;
              margin-top: 6px;
            }
          }

          .horizontal_separator {
            border: 1px solid #9D7259;
            margin: 10px 0px;
            width: 100%;
          }
  
          .vertical_separator {
            border: 1px solid #9D7259;
            margin: 0 10px;
            height: 50px;
          }
        }
      }
    }

    .mobile_horizontal_separator {
      height: 2px;
      width: 100%;
      background: #734425;
    }
  }
}
