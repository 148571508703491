.social_media_links {

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  
    .follow_us {
        font: normal normal normal 12px/16px Roboto;
        margin-bottom: 12px;
    }

    .social_links {
        display: flex;
        align-items: center;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        .links_set {
            display: flex;

            .link {
                width: 34px;
                height: 34px;
                margin-right: 16px;
                margin-bottom: 16px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media (max-height: 550px) {
        display: none;
    }
}

